import React from "react";
import { Route, NavLink, Switch } from "react-router-dom";
import "../styles/Modal.css";
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import ResetPassword from "./ResetPassword";

function Modal() {
  return (
    <div className="Modal">
      <div className="Modal-content">
        <NavLink className="Modal-close" to="/">
          &times;
        </NavLink>
        <ul className="Modal-menu">
          <li>
            <NavLink to="/signup">Sign Up</NavLink>
          </li>
          <li>
            <NavLink to="/signin">Sign In</NavLink>
          </li>
        </ul>
        <Switch>
          <Route path="/signup" exact component={SignUp} />
          <Route path="/signin" exact component={SignIn} />
          <Route path="/forgot-password" exact component={ResetPassword} />
        </Switch>
      </div>
    </div>
  );
}

export default Modal;
