import React from "react";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { cityOptions, groupOptions, CITY_DATES, DATE_VALUES } from "../data";
import InputField from "./InputField";
import SelectField from "./SelectField";
import firebase from "firebase/app";
import "firebase/firestore";
import { isPlainObject } from "lodash";
import axios from "axios";
import deepmerge from "deepmerge";

const VALID_USER_DOC_KEYS = [
  "answers",
  "firstName",
  "lastName",
  "eventDay",
  "group",
  "city",
];

const formKeys = [
  "firstName",
  "lastName",
  "email",
  "city",
  "eventDay",
  "group",
];

export default function EditUser({
  userUUID,
  initialValues,
  setEditingUserId,
  loadUsers,
}) {
  const actualInitialValues = {};

  formKeys.forEach((key) => {
    if (
      initialValues !== undefined &&
      Object.keys(initialValues).indexOf(key) === -1
    ) {
      actualInitialValues[key] = "";
    } else if (initialValues !== undefined) {
      actualInitialValues[key] = initialValues[formKeys];
    }
  });

  return (
    <div className="flex flex-column justify-center">
      <Link className="w-100 mt2 flex items-center b" to="/admin">
        <i className="material-icons mr2">group</i> All Users
      </Link>
      <h1>Edit User</h1>
      <Form
        initialValues={initialValues}
        onSubmit={async (values) => {
          const userDoc = {};

          if (isPlainObject(values)) {
            Object.entries(values).forEach(([k, v]) => {
              if (VALID_USER_DOC_KEYS.indexOf(k) !== -1) {
                userDoc[k] = v;
              }
            });
          }

          // Update or create doc if it doesn't exist
          const docAlreadyExistsCheck = await firebase
            .firestore()
            .collection("users")
            .doc(userUUID)
            .get();
          const docData = docAlreadyExistsCheck.data() || {};
          await firebase
            .firestore()
            .collection("users")
            .doc(userUUID)
            .set(deepmerge(docData, userDoc));

          if (values.email !== initialValues.email) {
            const idToken = await firebase.auth().currentUser.getIdToken(true);
            const response = await axios({
              url: `${process.env.REACT_APP_API_URL_BASE}/users/${userUUID}/email`,

              headers: {
                Authorization: `Bearer ${idToken}`,
              },
              method: "put",
              data: {
                email: values.email,
              },
            });
            console.log(response);
          }

          await loadUsers();
          setEditingUserId(null);
        }}
      >
        {({ submitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <InputField
              disabled={submitting}
              name="firstName"
              label="First Name"
            />
            <InputField
              disabled={submitting}
              name="lastName"
              label="Last Name"
            />
            <InputField
              disabled={submitting}
              name="email"
              label="Email Address"
            />
            <SelectField
              options={cityOptions}
              disabled={submitting}
              name="city"
              label="City"
            />

            <Field
              name="city"
              render={(props) => {
                if (
                  typeof props.input.value !== "string" ||
                  Object.keys(CITY_DATES).indexOf(props.input.value) === -1
                ) {
                  return (
                    <SelectField
                      options={[]}
                      disabled
                      name="eventDay"
                      label="Dates"
                    />
                  );
                }

                const daysOptions = CITY_DATES[props.input.value].map(
                  (date) => ({
                    label: date,
                    value: `${DATE_VALUES[date]}`,
                  })
                );

                return (
                  <SelectField
                    options={daysOptions}
                    disabled={submitting}
                    name="eventDay"
                    label="Dates"
                  />
                );
              }}
            />

            <SelectField
              options={groupOptions}
              disabled={submitting}
              name="group"
              label="Group"
            />
            <button className="Button w4 ph2 left b" type="submit">
              {submitting ? "Saving..." : "Save"}
            </button>
          </form>
        )}
      </Form>
    </div>
  );
}
