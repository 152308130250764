import React, { useContext } from "react";
import { QuizzesContext } from "../contexts/quizzes";
import Loading from "./Loading";
import firebase from "firebase/app";
import "firebase/firestore";
import "../styles/AdminQuizzes.css";
import QuizBuilder from "./QuizBuilderNew";
import ToggleableSection from "./ToggleableSection";
import { getEmptyQuiz } from "../utils";

export default function AdminQuizzes() {
  const { quizzes, fetchedQuizzes } = useContext(QuizzesContext);

  if (!fetchedQuizzes) return <Loading />;

  return (
    <div className="AdminQuizzes">
      <h1>Admin Quizzes</h1>
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          const newQuizRef = firebase.firestore().collection("quizzes").doc();
          newQuizRef.set(getEmptyQuiz());
        }}
      >
        Add Quiz
      </button>

      {Object.entries(quizzes).map(([id, quiz]) => {
        const quizTitle = quiz.name && quiz.name.length > 0 ? quiz.name : id;
        return (
          <ToggleableSection title={quizTitle}>
            <QuizBuilder
              initialValues={quiz}
              onSubmit={(updatedQuiz) => {
                firebase
                  .firestore()
                  .collection("quizzes")
                  .doc(id)
                  .set(updatedQuiz);
              }}
            />
            <div className="delete-quiz-wrapper">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    confirm(
                      `Are you sure you want to delete quiz: "${quizTitle}"?`
                    ) === true
                  ) {
                    firebase.firestore().collection("quizzes").doc(id).delete();
                  }
                }}
              >
                Delete Quiz
              </button>
            </div>
          </ToggleableSection>
        );
      })}
    </div>
  );
}
