import React, { useContext } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { Link, useParams } from "react-router-dom";
import "../styles/QuizHeader.css";
import logo from "../images/Subaru_logo.svg";
import { useQuizzes } from "../contexts/quizzes";
import find from "lodash/find";
import { QuizzesContext } from "../contexts/quizzes";
import "../styles/QuizSection.css";

export default function QuizHeader() {
  const { loggedIn } = useQuizzes();
  const { quizzes } = useContext(QuizzesContext);
  const { quizSlug } = useParams();
  const quiz = find(quizzes, { slug: quizSlug });

  return (
    <div className="QuizHeader h3 w-100 flex items-center bb b--light-gray bg-white">
      <div className="flex items-center justify-between mw-100 mw7-ns w-100 center">
        <div className="mw5 flex content-center">
          <Link to="/quizzes">
            <img alt="Subaru" src={logo} className="pl3" />
          </Link>
        </div>
        <div className="login pr3 flex items-center">
          {loggedIn && (
            <div className="flex flex-column">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  firebase.auth().signOut();
                  document.location.href = "/";
                }}
                className="f5 b"
              >
                Sign Out
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
