import max from "lodash/max";
import find from "lodash/find";
import uuidv4 from "uuid/v4";

export function getEmptyAnswer() {
  return {
    answer: "",
    isCorrect: false,
    uuid: uuidv4(),
  };
}

export function getEmptyQuestion() {
  return {
    question: "",
    uuid: uuidv4(),
    correctAnswerMsg: "",
    questionLabel: "",
    answers: [getEmptyAnswer()],
  };
}

export function getEmptyQuizSection() {
  return {
    title: "",
    uuid: uuidv4(),
    questions: [getEmptyQuestion()],
  };
}

export function getEmptyQuiz() {
  return {
    name: "",
    slug: "",
    sections: [getEmptyQuizSection()],
  };
}

export function getFilteredUsers(users, city, eventDay, group, search) {
  const filteredUsers = {};

  Object.keys(users).forEach((userUID) => {
    let isValid = true;

    // Search
    let lnv = true;
    let fnv = true;
    let sv = true;

    if (
      typeof search === "string" &&
      search.trim().length > 0 &&
      typeof users[userUID].firstName === "string" &&
      users[userUID].firstName
        .toLowerCase()
        .indexOf(search.trim().toLowerCase()) === -1
    ) {
      lnv = false;
    }

    if (
      typeof search === "string" &&
      search.trim().length > 0 &&
      typeof users[userUID].lastName === "string" &&
      users[userUID].lastName
        .toLowerCase()
        .indexOf(search.trim().toLowerCase()) === -1
    ) {
      fnv = false;
    }
    if (
      typeof search === "string" &&
      search.trim().length > 0 &&
      typeof users[userUID].email === "string" &&
      users[userUID].email
        .toLowerCase()
        .indexOf(search.trim().toLowerCase()) === -1
    ) {
      sv = false;
    }

    if (lnv || fnv || sv) {
      // found
    } else {
      isValid = false;
    }

    // City
    if (city && city.trim().length > 0 && users[userUID].city !== city) {
      isValid = false;
    }

    // Group
    if (group && group.trim().length > 0 && users[userUID].group !== group) {
      isValid = false;
    }

    // Event Day
    if (
      eventDay &&
      eventDay.toString().length > 0 &&
      parseInt(users[userUID].eventDay) !== parseInt(eventDay)
    ) {
      isValid = false;
    }

    if (isValid) {
      filteredUsers[userUID] = users[userUID];
    }
  });

  return filteredUsers;
}

export function getQuizStats(quiz, userAnswers) {
  const stats = {
    numCorrect: 0,
    numIncorrect: 0,
    numUnanswered: 0,
  };

  const allQuizQuestions = [];
  quiz.sections.forEach((section) => {
    section.questions.forEach((question) => {
      allQuizQuestions.push(question);
    });
  });

  const numQuestions = allQuizQuestions.length;

  Object.entries(userAnswers).forEach(([questionId, answerId]) => {
    const matchingQuizQuestion = find(allQuizQuestions, { uuid: questionId });
    const correctAnswer = find(matchingQuizQuestion?.answers, {
      isCorrect: true,
    });

    if (matchingQuizQuestion && correctAnswer && typeof answerId === "string") {
      if (answerId === correctAnswer.uuid) {
        stats.numCorrect++;
      } else {
        stats.numIncorrect++;
      }
    }
  });

  stats.numUnanswered = max([
    0,
    numQuestions - stats.numIncorrect - stats.numCorrect,
  ]);

  return stats;
}
