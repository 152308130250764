import { createContext, useEffect, useState, useContext, useMemo } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

export const QuizzesContext = createContext({
  quizzes: {},
  fetchedQuizzes: false,
});

export function QuizzesProvider(props) {
  // Quizzes state
  const [quizzes, setQuizzes] = useState({});
  const [fetchedQuizzes, setFetchedQuizzes] = useState(false);

  // User state
  const [checkedAuth, setCheckedAuth] = useState(false);
  const [user, setUser] = useState(null);
  const [userDoc, setUserDoc] = useState({});
  const [fetchedUserDoc, setFetchedUserDoc] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [checkedIsAdmin, setCheckedIsAdmin] = useState(false);

  // Online/Offline State via Firebase Realtime Database
  const [onlineTime, setOnlineTime] = useState(0);
  const [offlineTime, setOfflineTime] = useState(0);

  // Monitor Online/Offline Status via Firebase Realtime Database
  useEffect(() => {
    const connectedRef = firebase.database().ref(".info/connected");
    connectedRef.on("value", (snap) => {
      if (snap.val() === true) {
        setOnlineTime(new Date().valueOf());
      } else {
        setOfflineTime(new Date().valueOf());
      }
    });
  }, []);

  const isOffline = useMemo(() => {
    if (onlineTime !== 0 && offlineTime !== 0 && onlineTime < offlineTime) {
      return true;
    }
    return false;
  }, [onlineTime, offlineTime]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((userOrNull) => {
      setUser(userOrNull);
      setCheckedAuth(true);
      if (userOrNull === null) {
        setQuizzes({});
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!user) return;

    const unsubscribe = firebase
      .firestore()
      .collection("quizzes")
      .onSnapshot((snapshot) => {
        const newQuizzes = {};
        snapshot.forEach((doc) => {
          newQuizzes[doc.id] = doc.data();
        });
        setQuizzes(newQuizzes);
        setFetchedQuizzes(true);
      });

    return () => {
      unsubscribe();
    };
  }, [user]);

  useEffect(() => {
    if (!user) {
      setUserDoc({});
      setFetchedUserDoc(false);
      return;
    }

    const unsubscribe = firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const docData = doc.data() || {};
          setUserDoc(docData);
        }
        setFetchedUserDoc(true);
      });

    return () => {
      unsubscribe();
    };
  }, [user]);

  useEffect(() => {
    if (!user) {
      setIsAdmin(false);
      setCheckedIsAdmin(false);
      return;
    }

    const unsubscribe = firebase
      .firestore()
      .collection("admins")
      .doc(user.uid)
      .onSnapshot(
        (doc) => {
          const docData = doc.data() || {};
          if (docData.isAdmin === true) {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
          setCheckedIsAdmin(true);
        },
        (error) => {
          console.error(error);
          setCheckedIsAdmin(true);
        }
      );
    return () => {
      unsubscribe();
    };
  }, [user]);

  const loggedIn = useMemo(() => {
    return user !== null;
  }, [user]);

  const userReady = checkedAuth && checkedIsAdmin && fetchedUserDoc;

  return (
    <QuizzesContext.Provider
      value={{
        quizzes,
        fetchedQuizzes,
        userDoc,
        isAdmin,
        isOffline,
        user,
        loggedIn,
        checkedAuth,
        userReady,
        checkedIsAdmin,
      }}
    >
      {props.children ? props.children : null}
    </QuizzesContext.Provider>
  );
}

export function useQuizzes() {
  const quizzesContext = useContext(QuizzesContext);
  return quizzesContext;
}
