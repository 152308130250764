import React, { useState } from "react";
import "../styles/ToggleableSection.css";

export default function ToggleableSection(props) {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className="toggle">
      <div className="toggle-header">
        <div className="toggle-title">{props.title} </div>
        <button
          type="button"
          className="toggle-button"
          onClick={(e) => {
            e.preventDefault();
            setIsVisible((currentlyVisible) => !currentlyVisible);
          }}
        >
          {isVisible ? "-" : "+"}
        </button>
      </div>
      {isVisible ? (
        <div className="toggle-content">{props.children}</div>
      ) : null}
    </div>
  );
}
