import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import find from "lodash/find";
import UsersTable from "./UsersTable";
import Loading from "./Loading";
import "../styles/Admin.css";
import { Redirect } from "react-router-dom";
import { cityOptions, fulldayOptions, groupOptions } from "../data";
import Modal from "react-modal";
import EditUser from "./EditUser";
import UserResults from "./UserResults";
import { useQuizzes } from "../contexts/quizzes";
import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";
import { getFilteredUsers } from "../utils";

export default function Admin() {
  const [city, setCity] = useState(null);
  const [eventDay, setEventDay] = useState(null);
  const [group, setGroup] = useState(null);
  const [search, setSearch] = useState("");
  const [fetchedUsers, setFetchedUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const [editingUserId, setEditingUserId] = useState(null);

  const { loggedIn, checkedAuth, checkedIsAdmin, isAdmin, quizzes } =
    useQuizzes();

  const editingUser = useMemo(() => {
    if (!editingUserId) return null;
    return users[editingUserId] ?? null;
  }, [users, editingUserId]);

  const filteredUsers = useMemo(() => {
    return getFilteredUsers(users, city, eventDay, group, search);
  }, [users, city, eventDay, group, search]);

  const cityValue = find(cityOptions, { value: city });
  const groupValue = find(groupOptions, { value: group });
  const eventDayValue = find(fulldayOptions, { value: eventDay });

  const loadUsers = useCallback(async () => {
    const idToken = await firebase.auth().currentUser.getIdToken(true);
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL_BASE}/users`,

      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      method: "get",
    });

    setUsers(response.data);
    setFetchedUsers(true);
  }, []);

  useEffect(() => {
    if (!isAdmin || !checkedAuth) {
      setFetchedUsers(false);
      setUsers([]);
      return;
    }

    loadUsers();
  }, [isAdmin, checkedAuth]);

  if ((checkedAuth && !loggedIn) || (checkedIsAdmin && !isAdmin))
    return <Redirect to="/" />;

  if (!fetchedUsers || !checkedIsAdmin) {
    return <Loading />;
  }

  return (
    <div className="Admin f6 mw-100 w-100 ph4 pb6 pt3 center bg-white">
      <div className="Admin-filters flex items-center justify-between">
        <div className="Admin-filter w-25 pr3">
          <div>
            <label className="f7 ttu moon-gray tracked pb3">Search</label>
          </div>
          <input
            value={search}
            name="search"
            type="search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder="Search..."
          />
        </div>
        <div className="Admin-filter w-25 ph3">
          <div>
            <label className="f7 ttu moon-gray tracked pb3">City</label>
          </div>
          <Select
            isClearable
            id="city"
            value={cityValue}
            onChange={(value) => {
              if (value === null) {
                setCity(null);
              } else {
                setCity(value.value);
              }
            }}
            options={cityOptions}
            classNamePrefix="react-select"
          />
        </div>
        <div className="Admin-filter w-25 ph3">
          <div>
            <label className="f7 ttu moon-gray tracked pb3">Group</label>
          </div>
          <Select
            isClearable
            id="group"
            onChange={(value) => {
              if (value === null) {
                setGroup(null);
              } else {
                setGroup(value.value);
              }
            }}
            value={groupValue}
            options={groupOptions}
            classNamePrefix="react-select"
          />
        </div>
        <div className="Admin-filter w-25 pl3">
          <div>
            <label className="f7 ttu moon-gray tracked pb3">Dates</label>
          </div>
          <Select
            isClearable
            id="eventDay"
            value={eventDayValue}
            onChange={(value) => {
              if (value === null) {
                setEventDay(null);
              } else {
                setEventDay(value.value);
              }
            }}
            options={fulldayOptions}
            classNamePrefix="react-select"
          />
        </div>
      </div>

      <UsersTable
        loadUsers={loadUsers}
        users={filteredUsers}
        setEditingUserId={setEditingUserId}
      />

      {editingUser && (
        <Modal
          onRequestClose={() => {
            setEditingUserId(null);
          }}
          isOpen
        >
          <EditUser
            setEditingUserId={setEditingUserId}
            userUUID={editingUserId}
            initialValues={editingUser}
            loadUsers={loadUsers}
          />
          {Object.entries(quizzes).map(([quizId, quiz]) => {
            return (
              <div>
                <h5>{quiz.name ?? quizId}</h5>
                <UserResults quiz={quiz} userAnswers={editingUser.answers} />
              </div>
            );
          })}
        </Modal>
      )}
    </div>
  );
}
