import React from "react";

export default function UserResults({ quiz, userAnswers }) {
  if (!quiz || !quiz.sections) {
    return null;
  }

  return (
    <div>
      {quiz.sections.map((section) => (
        <div key={section.uuid}>
          <h5 className="mb3 mt4">{section.title}</h5>
          {section.questions.map((question) => {
            const correctAnswerUUIDs = question.answers
              .filter((answer) => answer.isCorrect)
              .map((question) => question.uuid);

            const answered =
              Object.keys(userAnswers || {}).indexOf(question.uuid) !== -1;
            const correct =
              correctAnswerUUIDs.indexOf(userAnswers?.[question.uuid]) !== -1;

            let userAnswer = "";
            if (answered) {
              const matches = question.answers.filter(
                (answer) => answer.uuid === userAnswers?.[question.uuid]
              );

              if (matches.length >= 1) {
                userAnswer = (
                  <div>
                    User Answer:
                    {matches[0].answer}
                  </div>
                );
              }
            }

            return (
              <div key={question.uuid} className="flex mb3">
                {!answered && (
                  <i className="material-icons-inline pl2 pr3 gray">help</i>
                )}
                {answered && correct && (
                  <i className="material-icons-inline correct-color pl2 pr3">
                    check_circle
                  </i>
                )}
                {answered && !correct && (
                  <i className="material-icons-inline incorrect-color pl2 pr3">
                    cancel
                  </i>
                )}

                <div>
                  {question.question}
                  <div className="b i">{userAnswer}</div>
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}
