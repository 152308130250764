import React, { useContext, useMemo } from "react";
import { useParams, Link, Route } from "react-router-dom";
import { QuizzesContext } from "../contexts/quizzes";
import Loading from "./Loading";
import intersection from "lodash/intersection";
import find from "lodash/find";
import Arrow from "./Arrow";
import { getQuizStats } from "../utils";
import "../styles/Quiz.css";

export default function QuizNew() {
  const { quizSlug } = useParams();
  const { quizzes, fetchedQuizzes, userReady, userDoc, user } =
    useContext(QuizzesContext);

  const userEmail = user && "email" in user ? user.email : "";

  const quiz = useMemo(() => {
    return find(quizzes, { slug: quizSlug });
  }, [quizSlug, quizzes, fetchedQuizzes]);

  if (!fetchedQuizzes || !userReady) return <Loading />;

  if (!quiz) {
    return <div>404</div>;
  }

  const quizResults = getQuizStats(quiz, userDoc.answers ?? {});

  // console.log(quizResults);

  return (
    <div className="mw-100 mw7-ns center">
      {quiz.sections.map((section) => {
        const sectionQuestionIDs = section.questions.map((s) => s.uuid);
        const numberOfQuestionsUserAnswered = intersection(
          sectionQuestionIDs,
          Object.keys(userDoc.answers ?? {})
        ).length;

        return (
          <ul key={section.uuid}>
            <Link
              to={`/quizzes/${quizSlug}/${section.uuid}/${section.questions[0].uuid}`}
            >
              <li className="flex items-start justify-between lh-title pv3 ph3 bg-white mv1">
                <div>
                  <h1 className="f5 ma0">{section.title}</h1>
                  <span className="f7 ttu tracked">
                    {numberOfQuestionsUserAnswered ===
                    sectionQuestionIDs.length ? (
                      <span className="correct-color">
                        <i className="material-icons-xs">check_circle</i>
                        Complete
                      </span>
                    ) : (
                      <span className="moon-gray">
                        {`${numberOfQuestionsUserAnswered} of ${section.questions.length} Answered`}
                      </span>
                    )}
                  </span>
                </div>
                <Arrow />
              </li>
            </Link>
          </ul>
        );
      })}
      <div className="ac bg-white  flex items-center justify-center pa4">
        <div className="Button-admin correct b tc db ph2 pt1 pb2 mr2 w3 mw4">
          <i className="material-icons-xs">check_circle</i>
          {quizResults.numCorrect}
        </div>
        <div className="Button-admin incorrect b tc db ph2 pt1 pb2 mr2 w3 mw4">
          <i className="material-icons-xs">cancel</i>
          {quizResults.numIncorrect}
        </div>
        <div className="Button-admin gray graybg b tc db ph2 pt1 pb2 w3 mw4">
          <i className="material-icons-xs">help</i>
          {quizResults.numUnanswered}
        </div>
      </div>
      {quizResults.numUnanswered === 0 && (
        <div className="mb0 bg-white pa3 center">
          <a
            target="_self"
            className="flex items-center justify-center mb4 b"
            rel="noopener noreferrer"
            href={`mailto:${userEmail || ""}?subject=${
              userDoc.firstName || ""
            }'s Subaru Quiz Results&body=${userDoc.firstName || ""} ${
              userDoc.lastName || ""
            }%0D%0A ${userDoc.city || ""}, ${
              userDoc.group || ""
            } %0D%0A Your Results Correct ${
              quizResults.numCorrect || ""
            }, Incorrect = ${quizResults.numIncorrect || ""}`}
          >
            Email your results
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`//surveys.autoevents.io/wrx-brz-survey/?firstname=${
              userDoc.firstName || ""
            }&lastname=${userDoc.lastName || ""}&email=${userEmail || ""}&city=${userDoc.city || ""}&group=${
              userDoc.group || ""
            }`}
          >
            <button className="Button w-100 ph2 center b">
              Tell us about your experience
            </button>
          </a>
        </div>
      )}
      {quizResults.numUnanswered === 0 && (
        <Route
          path="/quiz/survey"
          exact
          render={() => (
            <div className="Modal end">
              <div className="Modal-content">
                <Link className="Modal-close" to="/quiz">
                  &times;
                </Link>
                <h1 className="f2-ns f3 lh-title mb3">
                  Great work! You're almost done.
                </h1>
                <div className="QuizResults bg-white tc pb3">
                  <div className="mb3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`//surveys.autoevents.io/wrx-brz-survey/?firstname=${
                        userDoc.firstName || ""
                      }&lastname=${userDoc.lastName || ""}&email=${userEmail || ""}&city=${userDoc.city || ""}&group=${
                        userDoc.group || ""
                      }`}
                    >
                      <button className="Button w-100 ph2 center b">
                        Tell us about your experience
                      </button>
                    </a>
                  </div>
                  <div className="mb1">
                    <h1 className="f5 ph3 lh-solid moon-gray">Score</h1>
                  </div>
                  <div className="mb3 f7">
                    <div className="Button-xs correct flex items-center w-70 w-60-ns b tc lh-solid center mb3">
                      <i className="material-icons-small">check_circle</i>
                      <span className="b">
                        {quizResults.numCorrect} Correct
                      </span>
                    </div>
                    <div className="Button-xs incorrect flex items-center w-70 w-60-ns b tc lh-solid center mb3 ">
                      <i className="material-icons-small">cancel</i>
                      <span className="b">
                        {quizResults.numIncorrect} Incorrect
                      </span>
                    </div>
                    <div className="mb3">
                      <a
                        target="_self"
                        className="flex items-center justify-center pv2 b"
                        rel="noopener noreferrer"
                        href={`mailto:${userEmail || ""}?subject=${
                          userDoc.firstName || ""
                        }'s Subaru Quiz Results&body=${
                          userDoc.firstName || ""
                        } ${userDoc.lastName || ""}%0D%0A ${
                          userDoc.city || ""
                        }, ${userDoc.group || ""} %0D%0A Your Results Correct ${
                          quizResults.numCorrect || ""
                        }, Incorrect = ${quizResults.numIncorrect || ""}`}
                      >
                        Email your results
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        />
      )}
      <div className="mt4 tracked med-gray ttu f7 pt2 tc">
          Signed In As
      </div>
      <div className="med-gray mt2 f8 b pb2 tc">
          {userDoc.firstName} {userDoc.lastName}
      </div>
      <div className="med-gray mb2 f8 pb2 tc">
          {userEmail}
      </div>
    </div>
  );
}
