import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import ReactModal from "react-modal";
import "tachyons/css/tachyons.min.css";
import App from "./components/App";
import "./index.css";
import { QuizzesProvider } from "./contexts/quizzes";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

ReactModal.setAppElement("#modal");

ReactDOM.render(
  <Router>
    <QuizzesProvider>
      <App />
    </QuizzesProvider>
  </Router>,
  document.getElementById("root")
);
