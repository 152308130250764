import React, { useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";
import { useQuizzes } from "../contexts/quizzes";
import { daysOptions, GROUPS } from "../data";
import { getQuizStats } from "../utils";

function UserResultsShort({ userAnswers, quiz }) {
  const quizStats = getQuizStats(quiz, userAnswers || {});
  return (
    <div className="pb2" style={{ clear: "both", float: "left", display: "flex", alignItems: "center" }}>
      <div className="mr2 b">{quiz.name}</div>
      <div className="Button-admin correct b tc db ph1 pv1 mr2 w3 mw3">
        <i className="material-icons-xs">check_circle</i>
        {quizStats.numCorrect}
      </div>
      <div className="Button-admin incorrect b tc db ph1 pv1 mr2 w3 mw3">
        <i className="material-icons-xs">cancel</i>
        {quizStats.numIncorrect}
      </div>
      <div className="Button-admin gray graybg b tc db ph1 pv1 w3 mw3">
        <i className="material-icons-xs">help</i>
        {quizStats.numUnanswered}
      </div>
    </div>
  );
}

export default function UserRow(props) {
  const { quizzes } = useQuizzes();
  const [isDeleting, setIsDeleting] = useState(false);
  const {
    userUUID,
    loadUsers,
    setEditingUserId,
    user: { firstName, lastName, email, group, city, eventDay },
  } = props;

  let displayName = `${firstName || ""} ${lastName || ""}`;

  if (displayName.trim().length === 0) {
    displayName = <span>&ndash;</span>;
  }

  let eventDayString = <span>&ndash;</span>;
  if (eventDay) {
    const match = daysOptions.filter(
      (day) => parseInt(day.value) === parseInt(eventDay)
    );
    if (match.length === 1) {
      eventDayString = match[0].label;
    }
  }

  let groupString = <span>&ndash;</span>;
  if (group) {
    if (typeof GROUPS[group] === "string") {
      groupString = GROUPS[group];
    }
  }

  return (
    <tr className={`h3 bb b--light-gray ${isDeleting ? "deleting-item" : ""}`}>
      <td className="w-10 pl3 b pr2">{displayName}</td>
      <td className="w-10 mw4 pr2">
        {email && email.length ? email : <span>&ndash;</span>}
      </td>
      <td className="w-10 mw4 pr2">
        {city && city.length ? city : <span>&ndash;</span>}
      </td>
      <td className="w-10 mw2 pr2">{groupString}</td>
      <td className="w-10 mw4 pr2">{eventDayString}</td>
      <td className="w-20 pr0 pt2">
        <div>
          {Object.entries(quizzes).map(([quizId, quiz]) => (
            <UserResultsShort
              key={quizId}
              quiz={quiz}
              userAnswers={props.user.answers}
            />
          ))}
        </div>
      </td>
      <td className="w-10 mw2 pr2">
        <div className="w-10 mw2 pr2 flex items-end tr">
          <button
            type="button"
            className="Button-admin delete b fr db ph3 pv1 mr2 w3 mw3"
            onClick={async () => {
              if (
                window.confirm(
                  `Are you sure you want to delete: ${firstName} ${lastName} `
                )
              ) {
                setIsDeleting(true);
                const idToken = await firebase
                  .auth()
                  .currentUser.getIdToken(true);
                const response = await axios({
                  url: `${process.env.REACT_APP_API_URL_BASE}/users/${userUUID}`,
                  headers: {
                    Authorization: `Bearer ${idToken}`,
                  },
                  method: "delete",
                });
                setIsDeleting(false);
                await loadUsers();
              }
            }}
          >
            Delete
          </button>
          <button
            className="Button-admin edit-button fr mw3 b db ph3 pv1 mr2 w3 mw3"
            type="button"
            onClick={(e) => {
              console.log(userUUID);
              setEditingUserId(userUUID);
            }}
          >
            Edit
          </button>
        </div>
      </td>
    </tr>
  );
}
