import React from "react";
import { Form, Field } from "react-final-form";
import { Redirect } from "react-router-dom";
import hasIn from "lodash/hasIn";
import isEmpty from "lodash/isEmpty";
import isEmail from "validator/lib/isEmail";
import isString from "lodash/isString";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import InputField from "./InputField";
import SelectField from "./SelectField";
import Button from "./Button";
import { cityOptions, groupOptions, CITY_DATES, DATE_VALUES } from "../data";
import { useQuizzes } from "../contexts/quizzes";
import { FORM_ERROR } from "final-form";

const INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  city: "",
  eventDay: "",
  group: "",
};

function validate(values) {
  const errors = {};

  const requiredFields = [
    "email",
    "password",
    "firstName",
    "lastName",
    "password",
    "confirmPassword",
    "city",
    "eventDay",
    "group",
  ];
  requiredFields.forEach((field) => {
    if (!hasIn(values, field) || isEmpty(values[field])) {
      errors[field] = "This is required.";
    }
  });

  if (!isEmpty(values.password) && values.password.length < 6) {
    errors.password = "Password must be at least 6 characters long.";
  }

  // Verify that password and confirmPassword match
  if (
    !isEmpty(values.password) &&
    !isEmpty(values.confirmPassword) &&
    values.password !== values.confirmPassword
  ) {
    errors.confirmPassword = "Passwords must match.";
  }

  if (
    !isEmpty(values, "email") &&
    isString(values.email) &&
    !isEmail(values.email)
  ) {
    errors.email = "Email address must be a valid email address.";
  }

  return errors;
}

export default function SignUp() {
  const { loggedIn } = useQuizzes();

  if (loggedIn) {
    return <Redirect to="/quiz" />;
  }

  return (
    <Form
      onSubmit={async (values) => {
        const { firstName, lastName, email, password, city, eventDay, group } =
          values || {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            city: "",
            eventDay: "",
            group: "",
          };

        let userResult = null;

        try {
          userResult = await firebase
            .auth()
            .createUserWithEmailAndPassword(email, password);
        } catch (e) {
          return {
            [FORM_ERROR]: "Sign Up Failed",
          };
        }

        await firebase
          .firestore()
          .collection("users")
          .doc(userResult.user.uid)
          .set({
            firstName,
            lastName,
            city,
            eventDay,
            group,
          });
      }}
      initialValues={INITIAL_VALUES}
      validate={validate}
    >
      {({ handleSubmit, submitting, submitSucceeded, submitFailed }) => (
        <div>
          {submitSucceeded && (
            <span className="submitSuccessMsg f6 correct">
              <strong>Success!</strong>
              <br />
              Setting up your account...
              <br />
              If this screen does not advance, an account with your email
              address already exists. Try signing in.
            </span>
          )}
          {submitFailed && (
            <span className="submitErrorMsg f6 incorrect">
              <strong>Whoops!</strong><br />Check and make sure you've filled out all the fields below with no errors.<br />
              If everything is filled correctly, then your email address must already be in use. Try signing in.
              <br />
              <strong>Still having trouble?</strong> <a class="mailto-link" href="mailto:help@autoevents.io">help@autoevents.io</a>
            </span>
          )}
          <form onSubmit={handleSubmit}>
            <InputField
              disabled={submitting}
              name="firstName"
              label="First Name"
            />
            <InputField
              disabled={submitting}
              name="lastName"
              label="Last Name"
            />
            <InputField
              disabled={submitting}
              name="email"
              label="Email Address"
            />
            <InputField
              disabled={submitting}
              type="password"
              name="password"
              label="Password"
            />
            <InputField
              disabled={submitting}
              type="password"
              name="confirmPassword"
              label="Confirm Password"
            />
            <SelectField
              options={cityOptions}
              disabled={submitting}
              name="city"
              label="City"
            />

            <Field
              name="city"
              render={(props) => {
                if (
                  typeof props.input.value !== "string" ||
                  Object.keys(CITY_DATES).indexOf(props.input.value) === -1
                ) {
                  return (
                    <SelectField
                      options={[]}
                      disabled
                      name="eventDay"
                      label="Dates"
                    />
                  );
                }

                const daysOptions = CITY_DATES[props.input.value].map(
                  (date) => ({
                    label: date,
                    value: `${DATE_VALUES[date]}`,
                  })
                );

                return (
                  <SelectField
                    options={daysOptions}
                    disabled={submitting}
                    name="eventDay"
                    label="Dates"
                  />
                );
              }}
            />

            <SelectField
              options={groupOptions}
              disabled={submitting}
              name="group"
              label="Group"
            />

            <div className="pt3">
              <Button disabled={submitting} type="submit">
                Sign Up
              </Button>
            </div>
          </form>
        </div>
      )}
    </Form>
  );
}
