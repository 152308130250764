import React from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import Modal from "./Modal";
import wrxlogo from "../images/WRX.svg";
import partnerlogo from "../images/SparringPartners.png";
import logo from "../images/Subaru_logo_white.svg";
import "../styles/SplashScreen.css";
import { useQuizzes } from "../contexts/quizzes";

export default function SplashScreen() {
  const { loggedIn } = useQuizzes();

  if (loggedIn) {
    return <Redirect to="/quizzes" />;
  }

  return (
    <div className="SplashScreen">
      <div className="SplashScreen-header mw7-ns flex items-center justify-between h3 center">
        <img alt="Subaru" src={logo} className="pl3" />
        <Link className="SplashScreen-loginLink pr3" to="/signin">
          Sign In
        </Link>
      </div>
      <div className="center tc">
        <div className="SplashPartnersLogo w-80 mw8-ns pv4 pv5-ns center">
          <img alt="SparringPartners" src={partnerlogo} />
        </div>
        <div className="Challenge-button-container w-100 center">
          <Link to="/signup">
            <div className="Challenge-button br-pill pa3 b white ttu tracked w-70 w-40-ns center">
              Take the Challenge
            </div>
          </Link>
        </div>
      </div>
      <Switch>
        <Route path="/signup" component={Modal} />
        <Route path="/signin" component={Modal} />
        <Route path="/forgot-password" component={Modal} />
      </Switch>
    </div>
  );
}
