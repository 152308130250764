import { Switch, Route } from "react-router-dom";
import QuizHeader from "./QuizHeader";
import QuizDetailHeader from "./QuizDetailHeader";
import SplashScreen from "./SplashScreen";
import Quizzes from "./Quizzes";
import QuizNew from "./QuizNew";
import QuizSectionNew from "./QuizSectionNew";
import QuestionNew from "./QuestionNew";
import Admin from "./Admin";
import AdminQuizzes from "./AdminQuizzes";

import "../styles/App.css";

export default function App() {
  return (
    <>
      <Route path="/admin" component={QuizHeader} />
      <Route path="/quizzes/:quizSlug" exact component={QuizDetailHeader} />
      <Switch>
        <Route path="/" exact component={SplashScreen} />
        <Route path="/signup" exact component={SplashScreen} />
        <Route path="/signin" exact component={SplashScreen} />
        <Route path="/forgot-password" exact component={SplashScreen} />
        <Route path="/quizzes" exact component={Quizzes} />
        <Route path="/quizzes/:quizSlug" exact component={QuizNew} />
        <Route
          path="/quizzes/:quizSlug/:sectionId"
          exact
          component={QuizSectionNew}
        />
        <Route
          path="/quizzes/:quizSlug/:sectionId/:questionId"
          exact
          component={QuestionNew}
        />
        <Route path="/admin" exact component={Admin} />
        <Route path="/admin/quizzes" exact component={AdminQuizzes} />
      </Switch>
    </>
  );
}

// import React, { useContext } from "react";
// import { Redirect, Route, Switch } from "react-router-dom";
// import Admin from "./Admin";
// import QuizBuilder from "./QuizBuilder";
// import SplashScreen from "./SplashScreen";
// import Loading from "./Loading";
//
// import { QuizzesContext } from "../contexts/quizzes";

// import AdminQuizzes from "./AdminQuizzes";
// import Quizzes from "./Quizzes";
// import QuizNew from "./QuizNew";
// import QuizSectionNew from "./QuizSectionNew";
// import QuestionNew from "./QuestionNew";

//

// export default function App() {
//   const { checkedAuth, fetchedUser, loggedIn, isAdmin } =
//     useContext(QuizzesContext);

//   return (
//     <div className="App">
//       <Route path="/quizzes" component={QuizHeader} />
//       <Route path="/admin" component={QuizHeader} />
//       <Route path="/" exact component={SplashScreen} />
//       <Route path="/signup" exact component={SplashScreen} />
//       <Route path="/signin" exact component={SplashScreen} />
//       <Route path="/forgot-password" exact component={SplashScreen} />

//       {(!checkedAuth || !fetchedUser) && (
//         <Switch>
//           <Route path="/signin" render={() => null} />
//           <Route component={Loading} />
//         </Switch>
//       )}
//       {checkedAuth && !loggedIn && <Redirect to="/signin" />}
//       {checkedAuth && loggedIn && fetchedUser && (
//         <div className="main">
//           {isAdmin && <Route exact path="/admin" component={Admin} />}
//           {isAdmin && (
//             <Route exact path="/admin/quizzes" component={AdminQuizzes} />
//           )}
//           {isAdmin && (
//             <Route exact path="/admin/users/:userUID" component={Admin} />
//           )}
//           {isAdmin && (
//             <Route exact path="/admin/users/:quizSectionID" component={Admin} />
//           )}
//           {isAdmin && (
//             <Route
//               exact
//               path="/admin/users/:quizSectionID/:questionID"
//               component={Admin}
//             />
//           )}
//           {isAdmin && (
//             <Route exact path="/admin/quiz-builder" component={QuizBuilder} />
//           )}

//           {/* NEW ROUTES */}
//           <Route exact path="/quizzes/:quizId" component={QuizNew} />
//           <Route exact path="/quizzes" component={Quizzes} />
//           <Route
//             exact
//             path="/quizzes/:quizId/:quizSectionId"
//             component={QuizSectionNew}
//           />
//           <Route
//             exact
//             path="/quizzes/:quizId/:quizSectionId/:questionId"
//             component={QuestionNew}
//           />
//         </div>
//       )}
//     </div>
//   );
// }
