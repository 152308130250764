import React, { useContext } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { Link, Redirect } from "react-router-dom";
import Loading from "./Loading";
import partnerlogo from "../images/SparringPartners.png";
import logo from "../images/Subaru_logo.svg";
import { QuizzesContext } from "../contexts/quizzes";
import { useQuizzes } from "../contexts/quizzes";
import "../styles/Quizzes.css";

export default function Quizzes() {
  const { loggedIn } = useQuizzes();
  const { quizzes, fetchedQuizzes } = useContext(QuizzesContext);

  if (!fetchedQuizzes) return <Loading />;

  if (Object.keys(quizzes).length === 1) {
    return (
      <Redirect to={`/quizzes/${quizzes[Object.keys(quizzes)[0]].slug}`} />
    );
  }

  return (
    <div>
      <div className="QuizHeader h3 w-100 flex items-center bb b--light-gray bg-white">
        <div className="flex items-center justify-between mw-100 mw7-ns w-100 center">
          <div className="mw5">
            <Link to="/quizzes">
              <img alt="Subaru" src={logo} className="pl3" />
            </Link>
          </div>
          <div className="login pr3 flex items-center">
            {loggedIn && (
              <div className="flex flex-column">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    firebase.auth().signOut();
                    document.location.href = "/";
                  }}
                  className="f5 b"
                >
                  Sign Out
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="Quizzes">
        <div className="PartnersLogo w-80 mw8-ns pb2 pb3-ns center">
          <img alt="SparringPartners" src={partnerlogo} />
        </div>
        <div className="buttonList">
          {Object.entries(quizzes).map(([id, quiz]) => {
            const quizTitle = quiz.name && quiz.name.length ? quiz.name : id;
            return (
              <div key={id}>
                <Link className="QuizzesButton" to={`/quizzes/${quiz.slug}`}>
                  {quizTitle}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
