import React, { useContext } from "react";
import { useParams, Link } from "react-router-dom";
import find from "lodash/find";
import get from "lodash/get";
import Loading from "./Loading";
import Arrow from "./Arrow";
import { QuizzesContext } from "../contexts/quizzes";
import "../styles/QuizSection.css";
import { getQuizStats } from "../utils";

export default function QuizSectionNew() {
  const { quizzes, userDoc, fetchedQuizzes, userReady, user  } = useContext(QuizzesContext);
  const { sectionId, quizSlug } = useParams();

  const userEmail = user && "email" in user ? user.email : "";

  const quiz = find(quizzes, { slug: quizSlug });
  const quizSection = find(quiz?.sections, {
    uuid: sectionId,
  });

  if (!quizSection) {
    return null;
  }

  if (!fetchedQuizzes) {
    return <Loading />;
  }

  const quizResults = getQuizStats(quiz, userDoc.answers ?? {});

  return (
    <div className="QuizSection mw-100 mw7-ns pt5 center">
      {/* TODO Move the header into the App header? */}
      <div className="QuizSection-header fixed h3 w-100 flex items-center bb b--light-gray bg-white bg-white">
        <div className="flex items-center justify-between mw-100 mw7-ns w-100 center">
          <div className="pl3 review-title">
            <span className="f7 light-silver">Review</span>
            <h1 className="f5 lh-solid">{quizSection.title}</h1>
          </div>
          <Link to={`/quizzes/${quizSlug}`} className="b pr3">
            <i className="material-icons">home</i>
          </Link>
        </div>
      </div>
      {quizResults.numUnanswered === 0 && (
        <div className="mb0 bg-white pa3 center">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`//surveys.autoevents.io/wrx-brz-survey/?firstname=${
              userDoc.firstName || ""
            }&lastname=${userDoc.lastName || ""}&email=${userEmail || ""}&city=${userDoc.city || ""}&group=${
              userDoc.group || ""
            }`}
          >
            <button className="Button w-100 ph2 center b">
              Tell us about your experience
            </button>
          </a>
        </div>
      )}
      <ul className="QuizSection-questions list">
        {quizSection.questions.map((question) => {
          let userAnswers = userDoc.answers;
          const correctAnswer = find(question?.answers, { isCorrect: true });
          const userAnswer = get(userAnswers, question.uuid);
          const userAnswerObject = find(question?.answers, {
            uuid: userAnswer,
          });
          const userHasAnswered = typeof userAnswer === "string";
          const userAnsweredCorrectly = userAnswer === correctAnswer.uuid;

          return (
            <Link
              to={`/quizzes/${quizSlug}/${sectionId}/${question.uuid}`}
              key={question.uuid}
            >
              <li className="flex items-start justify-between lh-copy pv3 ph3 bg-white mv1">
                <span className="w-90 mw-90">
                  {question.question}
                  {userHasAnswered && (
                    <div className="flex i b">
                      {`You answered: `}
                      {userAnswerObject.answer}
                    </div>
                  )}
                </span>
                {userHasAnswered && !userAnsweredCorrectly && (
                  <i className="material-icons incorrect-color pl2">cancel</i>
                )}
                {userHasAnswered && userAnsweredCorrectly && (
                  <i className="material-icons correct-color pl2">
                    check_circle
                  </i>
                )}
                {!userHasAnswered && <Arrow />}
              </li>
            </Link>
          );
        })}
      </ul>
      <Link to={`/quizzes/${quizSlug}`} className="b mh3 text-center">
        <div className="BackAll-button br-pill mh3 pa3 mt1 b white tc flex justify-center">
          <i className="material-icons mr2">home</i> Return Home
        </div>
      </Link>
    </div>
  );
}
