import sortBy from 'lodash/sortBy';

export const APRIL_12_2022 = 'April 12-13, 2022';
export const APRIL_13_2022 = 'April 13-14, 2022';
export const APRIL_14_2022 = 'April 14-15, 2022';
export const APRIL_18_2022 = 'April 18-19, 2022';
export const APRIL_19_2022 = 'April 19-20, 2022';
export const APRIL_20_2022 = 'April 20-21, 2022';

export const MAY_4_2022 = 'May 4-5, 2022';
export const MAY_5_2022 = 'May 5-6, 2022';
export const MAY_9_2022 = 'May 9-10, 2022';
export const MAY_10_2022 = 'May 10-11, 2022';
export const MAY_11_2022 = 'May 11-12, 2022';
export const MAY_12_2022 = 'May 12-13, 2022';

export const DATE_VALUES = {
  [APRIL_12_2022]: 1,
  [APRIL_13_2022]: 2,
  [APRIL_14_2022]: 3,
  [APRIL_18_2022]: 4,
  [APRIL_19_2022]: 5,
  [APRIL_20_2022]: 6,
  [MAY_4_2022]: 7,
  [MAY_5_2022]: 8,
  [MAY_9_2022]: 9,
  [MAY_10_2022]: 10,
  [MAY_11_2022]: 11,
  [MAY_12_2022]: 12,
};

export const CITY_DATES = {
  'Washington, D.C.': [MAY_4_2022, MAY_5_2022, MAY_9_2022, MAY_10_2022, MAY_11_2022, MAY_12_2022],
  'Salt Lake City': [APRIL_12_2022, APRIL_13_2022, APRIL_14_2022, APRIL_18_2022, APRIL_19_2022, APRIL_20_2022],
};

export const cityOptions = sortBy(Object.keys(CITY_DATES), (o) => o).map((city) => ({
  label: city,
  value: city,
}));

export const daysOptions = Object.keys(CITY_DATES).reduce((dates, d) => {
  CITY_DATES[d].forEach((date) => {
    if (dates.indexOf(date) === -1) {
      dates.push(date);
    }
  });

  return dates;
}, []).map((date) => ({
  label: date,
  value: DATE_VALUES[date],
}));

export const fulldayOptions = sortBy(Object.keys(DATE_VALUES), (o) => DATE_VALUES[o]).map((date) => ({
  label: date,
  value: DATE_VALUES[date],
}));

export const GROUPS = {
  1: 'Red',
  2: 'Yellow',
  3: 'Green',
  4: 'Blue',
};

export const groupOptions = Object.keys(GROUPS).reduce((groupsOptions, groupID) => [
  ...groupsOptions,
  {
    label: `${GROUPS[groupID]}`,
    value: groupID,
  },
], []);
