import React, { useState, useCallback, useContext, useMemo } from "react";
import { useParams, Link, Redirect } from "react-router-dom";
import { Form, Field } from "react-final-form";
import firebase from "firebase/app";
import "firebase/firestore";
import find from "lodash/find";
import get from "lodash/get";
import findIndex from "lodash/findIndex";
import Loading from "./Loading";
import SuccessMessage from "./SuccessMessage";
import Button from "./Button";
import ErrorMessage from "./ErrorMessage";
import { QuizzesContext } from "../contexts/quizzes";
import "../styles/Question.css";
import set from "lodash/set";

function validate(values) {
  const errors = {};

  if (values.answer === "") {
    errors.answer = "Invalid";
  }

  return errors;
}

export default function QuestionNew() {
  const [redirectToSurvey, setRedirectToSurvey] = useState(false);
  const { questionId, quizSlug, sectionId } = useParams();
  const { quizzes, fetchedQuizzes, isOffline, userDoc, user, userReady } =
    useContext(QuizzesContext);

  const quiz = find(quizzes, { slug: quizSlug });

  const onSubmit = useCallback(
    async (values) => {
      let isLastQuestion = false;

      if (userDoc?.quizResults?.numUnanswered === 1) {
        isLastQuestion = true;
      }

      const doc = await firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get();

      const docData = doc.data() ?? {};
      console.log(docData);
      set(docData, `answers.${questionId}`, values.answer);
      console.log(docData);
      await firebase.firestore().collection("users").doc(user.uid).set(docData);

      if (isLastQuestion) {
        setRedirectToSurvey(true);
      }

      window.scrollTo(0, 0);
    },
    [user, userDoc, questionId]
  );

  if (!userReady || !fetchedQuizzes) {
    return <Loading />;
  }

  if (redirectToSurvey) {
    return <Redirect to="/quiz/survey" />;
  }

  const quizSection = find(quiz?.sections, { uuid: sectionId });
  const question = find(quizSection?.questions, { uuid: questionId });
  const correctAnswer = find(question?.answers, { isCorrect: true });
  const userAnswer = get(userDoc, `answers.${questionId}`, null);
  const userAnswerIsCorrect =
    correctAnswer &&
    typeof userAnswer === "string" &&
    correctAnswer.uuid === userAnswer;

  const questionIndex = findIndex(quizSection?.questions, { uuid: questionId });
  const nextQuestion = get(quizSection?.questions, `[${questionIndex + 1}]`);
  const userAnswered = typeof userAnswer === "string";

  const nextQuestionURL = `/quizzes/${quizSlug}/${sectionId}${
    nextQuestion?.uuid ? `/${nextQuestion.uuid}` : ""
  }`;

  const initialValues = {};
  if (typeof userAnswer === "string") {
    initialValues.answer = userAnswer;
  }

  if (!question) return null;

  return (
    <Form initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {({ submitting, handleSubmit, dirty }) => (
        <div className="Question mw-100 mw7-ns pt5 w-100 center">
          <div className="Question-header fixed h3 w-100 flex items-center bb b--light-gray bg-white">
            <div className="flex items-center justify-between mw-100 mw7-ns w-100 center">
              <div className="pl3">
                <Link to={`/quizzes/${quizSlug}`}>
                  <i className="material-icons">arrow_back</i>
                </Link>
              </div>
              <div className="Question-header-title tc lh-solid">
                <h1 className="f5 b lh-solid">
                  {`Question ${questionIndex + 1}`}
                </h1>
                <span className="f7 moon-gray">{quizSection.title}</span>
              </div>
              <div className="Question-index pr3">
                <Link to={`/quizzes/${quizSlug}/${sectionId}`}>
                  <i className="material-icons">format_list_numbered</i>
                </Link>
              </div>
            </div>
          </div>

          <div className="Question-content pb4 bg-white">
            {isOffline && (
              <div className="Question-content-offline pa3">
                Bad connection. Don't worry, we'll save your answer. If you're
                connected to WiFi, try using your mobile data.{" "}
                <strong>Attempting to reconnect...</strong>
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="carLabel f7 ttu b tracked pv2 tc white">{question.questionLabel}</div>
              {userAnswer && userAnswer === correctAnswer.uuid && (
                <SuccessMessage>
                  <div className="check flex items-start justify-between w-100">
                    <div className="w-70 b pv1">Correct!</div>

                    <Link
                      to={nextQuestionURL}
                      className="Button-small b"
                      style={{ backgroundColor: "#50d400", color: "#fff" }}
                    >
                      Next
                    </Link>
                  </div>
                </SuccessMessage>
              )}
              {userAnswer && userAnswer !== correctAnswer.uuid && (
                <ErrorMessage>
                  <div className="check flex items-start justify-between w-100">
                    <div className="w-70 b pv1">Incorrect.</div>
                    <Link
                      to={nextQuestionURL}
                      className="Button-small b"
                      style={{ backgroundColor: "#e65932", color: "#fff" }}
                    >
                      Next
                    </Link>
                  </div>
                </ErrorMessage>
              )}
              <p className="Question-title pb3 ph3 pt3">{question.question}</p>
              <ul className="Question-answers list ph3">
                {question.answers.map((answer) => {
                  let className = "";
                  let checkOrCloseOrNothing = null;

                  if (
                    userAnswered &&
                    correctAnswer &&
                    userAnswer === correctAnswer.uuid &&
                    answer.uuid === correctAnswer.uuid
                  ) {
                    className = "correct-color";
                    checkOrCloseOrNothing = (
                      <i className="material-icons-inline correct-color pl2">
                        check_circle
                      </i>
                    );
                  }
                  // User chose this answer, and it is incorrect
                  else if (
                    userAnswered &&
                    userAnswer === answer.uuid &&
                    correctAnswer &&
                    answer.uuid !== correctAnswer.uuid
                  ) {
                    className = "incorrect-color";
                    checkOrCloseOrNothing = (
                      <i className="material-icons-inline incorrect-color pl2">
                        cancel
                      </i>
                    );
                  } else if (
                    userAnswered &&
                    correctAnswer &&
                    correctAnswer.uuid === answer.uuid &&
                    userAnswer !== answer.uuid
                  ) {
                    className = "correct-color";
                    checkOrCloseOrNothing = (
                      <i className="material-icons-inline correct-color pl2">
                        check_circle
                      </i>
                    );
                  }

                  return (
                    <span key={answer.uuid} className="w-100">
                      <li
                        className={`Question-answer flex justify-start items-start lh-copy pv2 ${className}`}
                      >
                        <label
                          htmlFor={answer.uuid}
                          className={`b db pt1 ${className}`}
                        >
                          <Field
                            disabled={userAnswered || submitting}
                            className={className}
                            component="input"
                            type="radio"
                            name="answer"
                            id={answer.uuid}
                            value={answer.uuid}
                          />
                          <span>{answer.answer}</span>
                          {checkOrCloseOrNothing}
                        </label>
                      </li>
                    </span>
                  );
                })}
              </ul>
              {!userAnswered && (
                <div className="w-100 mw7-ns tc center mt4 ph3">
                  <Button disabled={!dirty || submitting} type="submit">
                    Submit
                    {submitting ? "ting" : ""}
                  </Button>
                  <Link to={nextQuestionURL} className="f7 moon-gray">
                    Skip Question
                  </Link>
                </div>
              )}
            </form>
          </div>
        </div>
      )}
    </Form>
  );
}
