import React from "react";
import UserRow from "./UserRow";
import "../styles/UsersTable.css";

export default function UsersTable({ setEditingUserId, users, loadUsers }) {
  return (
    <div>
      <p>
        <strong>{Object.keys(users).length} Users</strong>
      </p>
      <table className="UsersTable bg-white">
        <thead>
          <tr>
            <th>
              <button type="button" className="ttu tracked f7">
                Name
              </button>
            </th>
            <th>
              <button type="button" className="ttu tracked f7">
                Email
              </button>
            </th>
            <th>
              <button type="button" className="ttu tracked f7">
                City
              </button>
            </th>
            <th>
              <button type="button" className="ttu tracked f7">
                Group
              </button>
            </th>
            <th>
              <button type="button" className="ttu tracked f7">
                Dates
              </button>
            </th>
            <th>
              <button type="button" className="ttu tracked f7">
                Results
              </button>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {Object.keys(users).map((uid) => (
            <UserRow
              key={uid}
              setEditingUserId={setEditingUserId}
              userUUID={uid}
              loadUsers={loadUsers}
              user={users[uid]}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
