import React from "react";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import "../styles/QuizBuilderNew.css";
import { XCircle, CheckCircle } from "phosphor-react";
import {
  getEmptyAnswer,
  getEmptyQuestion,
  getEmptyQuizSection,
} from "../utils";

function AnswersField(props) {
  return (
    <FieldArray name={props.name}>
      {(answersProps) => (
        <div className="quiz-builder-answers">
          <h3>Answers</h3>
          <div>
            {answersProps.fields.map((answersField) => (
              <div className="quiz-builder-answer-row" key={answersField}>
                <Field name={`${answersField}.isCorrect`}>
                  {({ input: { value, onChange } }) => (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        if (value === true) {
                          onChange(false);
                        } else {
                          onChange(true);
                        }
                      }}
                    >
                      {value === true ? (
                        <CheckCircle color="#50d400" />
                      ) : (
                        <XCircle color="#e65932" />
                      )}
                    </button>
                  )}
                </Field>
                <Field
                  placeholder="Answer"
                  name={`${answersField}.answer`}
                  component="input"
                  type="text"
                />
              </div>
            ))}
          </div>
          <button
            type="button"
            onClick={() => answersProps.fields.push(getEmptyAnswer())}
          >
            Add Answer
          </button>
        </div>
      )}
    </FieldArray>
  );
}

function SectionsField() {
  return (
    <FieldArray name="sections">
      {(sectionsProps) => (
        <div>
          <h3>Sections</h3>
          {sectionsProps.fields.map((sectionsField, index) => (
            <div class="quiz-builder-section" key={sectionsField}>
              <label>Section Title</label>
              <Field
                component="input"
                type="text"
                name={`${sectionsField}.title`}
                placeholder="Section Title"
              />
              <FieldArray name={`${sectionsField}.questions`}>
                {(questionsProps) => (
                  <div>
                    <div>
                      {questionsProps.fields.map((questionsField) => (
                        <div key={questionsField}>
                          <div>
                            <label>Question</label>
                            <Field
                              placeholder="Question"
                              component="input"
                              type="text"
                              name={`${questionsField}.question`}
                            />
                          </div>
                          <div>
                            <label>
                              Message to show if it is answered correctly
                            </label>
                            <Field
                              placeholder="Correct answer message"
                              component="textarea"
                              name={`${questionsField}.correctAnswerMsg`}
                            />
                          </div>
                          <div>
                            <label>
                              Label
                            </label>
                            <Field
                              placeholder="Car Model..."
                              component="input"
                              type="text"
                              name={`${questionsField}.questionLabel`}
                            />
                          </div>
                          <AnswersField name={`${questionsField}.answers`} />
                        </div>
                      ))}
                    </div>
                    <button
                      type="button"
                      onClick={() =>
                        questionsProps.fields.push(getEmptyQuestion())
                      }
                    >
                      Add Question
                    </button>
                  </div>
                )}
              </FieldArray>
            </div>
          ))}
          <button
            type="button"
            onClick={() => sectionsProps.fields.push(getEmptyQuizSection())}
          >
            Add Section
          </button>
        </div>
      )}
    </FieldArray>
  );
}

export default function QuizBuilderNew(props) {
  const { onSubmit, initialValues } = props;
  return (
    <div className="quiz-builder-new">
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <label>Name</label>
            <Field
              component="input"
              type="text"
              name={`name`}
              placeholder="Name"
            />
            <label>Slug</label>
            <Field
              component="input"
              type="text"
              name={`slug`}
              placeholder="Slug"
            />

            <SectionsField />

            <button type="submit">Save Quiz</button>
          </form>
        )}
      />
    </div>
  );
}
