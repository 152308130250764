import React from "react";
import { Redirect, Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import { Form } from "react-final-form";
import isEmpty from "lodash/isEmpty";
import isEmail from "validator/lib/isEmail";
import InputField from "./InputField";
import Button from "./Button";
import { useQuizzes } from "../contexts/quizzes";

const initialState = {
  email: "",
  password: "",
};

function validate(values) {
  const errors = {};

  if (isEmpty(values.email)) {
    errors.email = "This field is required.";
  } else if (!isEmail(values.email)) {
    errors.email = "Enter a valid email address.";
  }

  if (isEmpty(values.password)) {
    errors.password = "This field is required.";
  }

  return errors;
}

export default function SignIn() {
  const { loggedIn } = useQuizzes();

  console.log(loggedIn);

  if (loggedIn) {
    return <Redirect to="/quizzes" />;
  }

  return (
    <Form
      onSubmit={({ email, password }) => {
        return new Promise((resolve) => {
          firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(
              () => resolve(),
              () => resolve({ error: true })
            )
            .catch(() => resolve({ error: true }));
        });
      }}
      initialState={initialState}
      validate={validate}
    >
      {({
        submitting,
        handleSubmit,
        dirtySinceLastSubmit,
        submitFailed,
        submitSucceeded,
      }) => (
        <form onSubmit={handleSubmit}>
          {submitFailed && (
            <span className="submitErrorMsg f6 incorrect">
              <strong>Your email or password was incorrect.</strong>
              <br />
              <strong>Trouble?</strong> help@autoevents.io
            </span>
          )}
          <InputField
            disabled={submitting}
            name="email"
            label="Email Address"
          />
          <InputField
            disabled={submitting}
            name="password"
            type="password"
            label="Password"
          />
          <Button disabled={submitting} type="submit">
            Sign In
          </Button>
          {!dirtySinceLastSubmit && submitSucceeded && (
            <span className="submitSuccessMsg f6 correct">
              <strong>Logging in...</strong>
            </span>
          )}
          <div className="tc pt2 f6">
            <Link to="/forgot-password">Forgot password?</Link>
          </div>
        </form>
      )}
    </Form>
  );
}
